import { NextPage } from 'next';
import Link from 'next/link';
import GNOFooter from '../components/wherewego/Footer';
import GNOHeader from '../components/wherewego/Header';

const NotFoundPage: NextPage = () => {
  return (
    <>

      <GNOHeader />

      <div className="flex justify-center flex-col items-center h-40 px-1.5 md:h-128">
        <h1 className="self-center text-lg font-bold leading-tight text-center font-heading text-primary-dark md:text-2xl">
          Sorry, looks like you’re trying to access a page or resource that
          doesn’t exist.{' '}
        </h1>
        <div className="container flex flex-col items-center justify-center gap-4 py-8 md:flex-row">
          <Link

            href={'/careerguide'}
            className="p-4 font-bold border-2 border-solid rounded-full border-careerPageHeading text-careerPageHeading hover:text-white hover:bg-careerPageHeading"
          >
            Explore Careers
          </Link>
          <Link
            className="p-4 font-bold border-2 border-solid rounded-full border-primary text-primary hover:text-white hover:bg-primary"

            href={'/training'}
          >
            Find Training
          </Link>
        </div>
      </div>

      <GNOFooter />
    </>
  );
};

export default NotFoundPage;
